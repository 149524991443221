<template>
    <!-- 邀请码页面 -->
    <div class="ant-card" id="invite_code">
        <div class="ant-card-body" v-if="$route.meta.isShow">
            <a-tabs :default-active-key="tabActived" @change="changeTab">
                <a-tab-pane key="1" tab="线上码">
                    <div class="head-box">
                        <div class="table-operator">
                            <a-button icon="plus" type="primary" @click="handleAction(0)" v-show="isShowAdd">
                                创建邀请码
                            </a-button>
                        </div>
                        <a-form
                            layout="inline"
                            :model="form"
                            @submit="handleSearch"
                            @submit.native.prevent
                        >
                            <a-form-model-item label="邀请码类型">
                                <a-select
                                    style="width: 200px"
                                    v-model="form.type"
                                    @change="typeChange"
                                >
                                    <a-select-option v-for="item in typeList" :key="item.key">
                                        {{ item.labelText }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                            <a-form-model-item label="创建日期">
                                <a-range-picker @change="onChangeDate"/>
                            </a-form-model-item>
                            <a-form-model-item>
                                <a-input v-model="form.title" placeholder="请输入邀请码标题">
                                    <a-icon
                                        slot="prefix"
                                        type="search"
                                        style="color: rgba(0, 0, 0, 0.25)"
                                    />
                                </a-input>
                            </a-form-model-item>
                            <a-form-model-item>
                                <a-button icon="search" type="primary" html-type="submit"
                                >搜索
                                </a-button
                                >
                            </a-form-model-item>
                        </a-form>
                    </div>
                    <div class="content-box">
                        <a-table
                            :columns="columns"
                            :data-source="tableList"
                            :loading="tableLoading"
                            :pagination="false"
                        >
              <span slot="type" slot-scope="text, record">
                <template v-if="record.type == 0">线下码</template>
                <template v-if="record.type == 1">线上码</template>
              </span>
                            <span slot="status" slot-scope="text, record">
                <template v-if="record.status == 0">
                  <a-badge
                      status="success"
                      :text="record.statusText"
                      class="success-text"
                  />
                </template>
                <template v-if="record.status == 1">
                  <a-badge
                      status="error"
                      :text="record.statusText"
                      class="error-text"
                  />
                </template>
              </span>
                            <span slot="price" slot-scope="price, record">
                <span v-show="record.pricingType == 0">
                  {{ record.businessType == 2 ? record.price : "免费" }}</span
                >
                <span v-show="record.pricingType == 1">{{ record.price }}</span>
                <span v-show="record.pricingType == 2">{{
                        record.businessType == 2 ? record.price : "不支持单独售卖"
                    }}</span>
              </span>
                            <span slot="action" slot-scope="text, record">
                <a
                    class="btn-edit mr20"
                    v-if="record.status == 0 && isShowUpd"
                    @click="handleAction(record.key)"
                >编辑</a
                >
                <a
                    v-show="isShowShare"
                    class="btn-edit mr20"
                    @click="shareClick(record)"
                >分享</a
                >
                <a
                    class="btn-edit mr20"
                    v-if="record.status == 1 && isShowDetail"
                    @click="handleAction(record.key)"
                >详情</a
                >
                <a
                    class="btn-invalid mr20"
                    v-if="record.status == 0 && isShowDisabled"
                    @click="handleInvalid(record.orderNo)"
                >立即失效</a
                >
                <a
                    v-show="isShowUse"
                    class="btn-record"
                    @click="handleRecord(record)"
                >使用记录</a
                >
              </span>
                        </a-table>
                        <!-- 分页功能 -->
                        <MyPagination
                            :count="count"
                            :pageNo="form.pageNo"
                            @showSizeChangeFn="showSizeChangeFn"
                            v-show="tableList.length > 0"
                        />
                    </div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="邀请码查询">
                    <UsedCode></UsedCode>
                </a-tab-pane>
                <!-- <a-tab-pane key="2" tab="新版线下码">
                  <invite-member-code></invite-member-code>
                </a-tab-pane>
                <a-tab-pane key="3" tab="新版线上码">
                  <invite-online-code></invite-online-code>
                </a-tab-pane> -->
            </a-tabs>
            <!-- 分享的弹框 -->
            <a-modal v-model="modalVisible" :footer="null" width="40%">
                <div class="share">分享</div>
                <a-tabs default-active-key="1">
                    <!-- <a-tab-pane key="1" tab="小程序">
                      <div class="share_modal">
                        <div><span>二维码</span></div>
                        <div class="share_img">
                          <img :src="appletImageUrl" alt="二维码" />
                        </div>
                        <div class="share_text">
                          <a-button @click="downImg">下载</a-button>
                          <div style="margin-top: 20px">扫码后转发页面至微信群</div>
                          <div>群内用户可领取邀请码(每人限领一次)</div>
                        </div>
                      </div>
                    </a-tab-pane> -->
                    <a-tab-pane key="1" tab="H5">
                        <div>
                            <span style="margin-right: 20px">链接</span>
                            <a-input
                                placeholder="H5链接"
                                disabled
                                style="width: 75%"
                                v-model="shareInputVal"
                            />
                            <a-button
                                id="copy_text"
                                :data-clipboard-text="shareInputVal"
                                type="primary"
                                style="margin-left: 20px"
                                @click="handleCopyFun"
                            >
                                复制
                            </a-button>
                        </div>
                    </a-tab-pane>
                </a-tabs>
            </a-modal>
            <!-- 返回顶部 -->
            <a-back-top :target="targetFn" :visibilityHeight="100"/>
        </div>
        <router-view v-else/>
    </div>
</template>

<script>
import Clipboard from "clipboard"; // 复制文字
import {domainName} from "@/config"
import {Modal} from "ant-design-vue";
import MyPagination from "@/components/pagination/MyPagination";
import {
    InvitationCodeApi,
    InvitationCodeDisabledApi,
} from "@/request/api/marketingCenter";
import {throttle} from "@/utils/tools";
import {codeFn} from "@/utils/tools";
import UsedCode from "./componets/usedCodeComp"

export default {
    components: {
        MyPagination,
        UsedCode
        // inviteMemberCode,
        // inviteOnlineCode
    },
    data() {
        return {
            tabActived: '1',
            modalVisible: false, // 分享弹框是否显示
            appletImageUrl: "", // 分享弹框的二维码链接
            shareInputVal: '',
            form: {
                pageNo: 1,
                pageSize: 20,
                startTime: "",
                endTime: "",
                title: "",
                type: "",
            },
            count: 0,
            columns: [
                {title: "邀请码标题", dataIndex: "title", key: "title", width: "10%"},
                {
                    title: "类型",
                    dataIndex: "type",
                    key: "type",
                    width: "5%",
                    scopedSlots: {customRender: "type"},
                },
                {title: "批次名称", dataIndex: "name", width: "12%", key: "name"},
                {
                    title: "邀请码数量",
                    dataIndex: "quantity",
                    key: "quantity",
                    width: "7%",
                },
                {
                    title: "领取数量",
                    dataIndex: "receiveQuantity",
                    key: "receiveQuantity",
                    width: "6%",
                },
                {
                    title: "商品名称",
                    dataIndex: "businessName",
                    width: "18%",
                    key: "businessName",
                },
                {
                    title: "定价",
                    dataIndex: "price",
                    key: "price",
                    width: "6%",
                    scopedSlots: {customRender: "price"},
                },
                {
                    title: "生效时间",
                    dataIndex: "startTime",
                    width: "7%",
                    key: "startTime",
                },
                {
                    title: "失效时间",
                    dataIndex: "endTime",
                    width: "7%",
                    key: "endTime",
                },
                {
                    title: "状态",
                    dataIndex: "status",
                    key: "status",
                    width: "5%",
                    scopedSlots: {customRender: "status"},
                },
                {
                    title: "操作",
                    dataIndex: "action",
                    key: "action",
                    scopedSlots: {customRender: "action"},
                },
            ],
            typeList: [
                {key: "", labelText: "全部类型"},
                {key: 0, labelText: "线下码"},
                {key: 1, labelText: "线上码"},
            ],
            tableList: [],
            tableLoading: false,
            isShowAdd: false, // 是否显示新增的按钮
            isShowUpd: false, // 是否显示编辑的按钮
            isShowDisabled: false, // 立即失效是否显示
            isShowDetail: false, // 详情是否显示
            isShowUse: false, // 使用记录是否显示
            isShowShare: false, // 使用记录是否显示
        };
    },
    watch: {
        $route(to) {
            if (to.path === "/marketingCenter/inviteCode") {
                this.getInviteCodeList();
            }
        },
    },
    created() {
        this.getInviteCodeList();
        if (codeFn("/admin/dealer/invitation-code/saveDealerInvitationCode")) {
            this.isShowAdd = true;
        }
        if (codeFn("/admin/dealer/invitation-code/updDealerInvitationCode")) {
            this.isShowUpd = true;
        }
        if (codeFn("/admin/dealer/invitation-code/disabled")) {
            this.isShowDisabled = true;
        }
        if (codeFn("/admin/dealer/invitation-code/getDealerInvitationCodeResult")) {
            this.isShowDetail = true;
        }
        if (
            codeFn("/admin/dealer/invitation-code/queryDealerInvitationRecordResult")
        ) {
            this.isShowUse = true;
        }
        if (codeFn("/admin/dealer/invitation-code/shareAppletCode")) {
            this.isShowShare = true;
        }
    },
    methods: {
        changeTab(v) {
            // this.tabActived = v;
        },
        // 回到顶部的函数
        targetFn() {
            return document.querySelector("#invite_code");
        },
        // 分享的按钮
        shareClick(record) {
            this.modalVisible = true;
            this.shareInputVal = domainName + 'h5/dyw/#/receiveCode/' + record.orderNo;
        },
        // 分享弹框复制按钮
        handleCopyFun() {
            let clipboard = new Clipboard("#copy_text");
            clipboard.on("success", (e) => {
                message.success("复制成功");
                this.modalVisible = false;
                clipboard.destroy(); // 释放内存
            });
            clipboard.on("error", (e) => {
                message.error("复制失败"); // 不支持复制
                this.modalVisible = false;
                clipboard.destroy(); // 释放内存
            });
        },
        // 分享的下载按钮
        downImg() {
            const image = new Image();
            // 跨域，解决跨域 Canvas 污染问题
            image.setAttribute("crossOrigin", "anonymous");
            image.onload = function () {
                const canvas = document.createElement("canvas");
                canvas.width = image.width;
                canvas.height = image.height;
                const context = canvas.getContext("2d");
                context.drawImage(image, 0, 0, image.width, image.height);
                const url = canvas.toDataURL("image/png"); // 得到图片的base64编码数据
                const a = document.createElement("a"); // 生成一个a元素
                const event = new MouseEvent("click"); // 创建一个单击事件
                a.download = "分享二维码" || "photo"; // 设置图片名称
                a.href = url; // 将生成的URL设置为a.href属性
                a.dispatchEvent(event); // 触发a的单击事件
            };
            image.src = this.appletImageUrl;
        },
        // 邀请码列表数据
        getInviteCodeList() {
            this.tableLoading = true;
            InvitationCodeApi(this.form).then((res) => {
                if (res.code == 200) {
                    this.count = res.data.count;
                    let newData = res.data.data.map((item) => {
                        return JSON.parse(JSON.stringify(item).replace(/id/g, "key"));
                    });
                    this.tableList = newData;
                }
                this.tableLoading = false;
            });
        },
        // 分页切换页数
        showSizeChangeFn(pageNo, pageSize) {
            this.form.pageNo = pageNo;
            this.form.pageSize = pageSize;
            throttle(this.getInviteCodeList());
        },
        // 搜索 - 邀请码类型
        typeChange(val) {
            this.form.type = val;
            this.form.pageNo = 1;
            throttle(this.getInviteCodeList());
        },
        // 搜索 - 修改时间
        onChangeDate(date, dateString) {
            if (dateString[0] != "") {
                this.form.startTime = dateString[0] + " 00:00:00";
                this.form.endTime = dateString[1] + " 23:59:59";
            } else {
                this.form.startTime = "";
                this.form.endTime = "";
            }
        },
        // 搜索
        handleSearch() {
            throttle(this.getInviteCodeList());
        },
        // 创建 / 编辑 / 详情
        handleAction(id) {
            if (id == 0) {
                this.$router.push(`/marketingCenter/inviteCode/actionInviteCode/${id}`);
            } else {
                location.href = `/marketingCenter/inviteCode/actionInviteCode/${id}`;
            }
        },
        // 立即失效
        handleInvalid(orderNo) {
            const that = this;
            Modal.confirm({
                title: "设为失效后，邀请码将不可继续使用!",
                okText: "确定",
                okType: "danger",
                cancelText: "取消",
                onOk() {
                    InvitationCodeDisabledApi({orderNo: orderNo}).then((res) => {
                        if (res.code == 200) {
                            that.$message.success("失效成功");
                            that.getInviteCodeList();
                        }
                    });
                },
            });
        },
        // 使用记录
        handleRecord(record) {
            let recordCodeData = {
                orderNo: record.orderNo,
                title: record.title,
                startTime: record.startTime,
                endTime: record.endTime,
            };
            recordCodeData = JSON.stringify(recordCodeData);
            this.$router.push(
                `/marketingCenter/inviteCode/usedCode/${recordCodeData}`
            );
        },
    },
};
</script>

<style lang="less" scoped>
#invite_code {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.head-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.table-operator {
    margin-right: 30px;
}

.mr20 {
    margin-right: 20px;
}

/deep/ .success-text .ant-badge-status-text {
    color: #52c41a;
}

/deep/ .error-text .ant-badge-status-text {
    color: #9fa5af;
}

.share {
    width: 95%;
    text-align: center;
    height: 30px;
    line-height: 30px;
    background-color: #efefef;
}

.share_modal {
    display: flex;
    
    .share_img {
        margin-left: 30px;
        width: 150px;
        height: 150px;
        
        img {
            width: 100%;
            height: 100%;
        }
    }
    
    .share_text {
        margin-left: 50px;
    }
}
</style>
