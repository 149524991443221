<template>
    <div class="ant-card">
        <div class="ant-card-body">
            <div class="head-box">
                <div class="flexbox middle" style="margin-bottom: 30px">
                    <a-input v-model="uniqueId" placeholder="请输入邀请码" style="width:300px" class="margin_right20">
                        <a-icon slot="prefix" type="search" style="color: rgba(0, 0, 0, 0.25)"/>
                    </a-input>
                    <a-button icon="search" type="primary" html-type="submit" @click="handleSearch">搜索</a-button>
                </div>
            </div>
            <div class="content-box">
                <a-table :columns="columns" :data-source="tableList" :loading="tableLoading" :pagination="false">
                    <span slot="status" slot-scope="status, record">
                        <span>{{record.status == 0 ? '未领取' : record.status == 1 ? '已领取' : '已失效'}}</span>
                    </span>
                    <span slot="operation" slot-scope="operation, record">
                        <a class="margin_right20" @click="shareClick(record)">分享</a>
                        <a v-if="isShowDisabled" class="btn-invalid mr20" @click="handleInvalid(record)">立即失效</a>
                    </span>
                </a-table>
                <!-- 分享的弹框 -->
                <a-modal v-model="shareModalVisible" :footer="null" width="40%">
                    <div class="share">分享</div>
                    <a-tabs default-active-key="1">
                        <a-tab-pane key="1" tab="H5">
                            <div>
                                <span style="margin-right: 20px">链接</span>
                                <a-input placeholder="H5链接" disabled style="width: 80%" v-model="shareInputVal"/>
                                <a-button id="copy_text" :data-clipboard-text="shareInputVal" type="primary" style="margin-left: 20px" @click="handleCopyFun">复制</a-button>
                            </div>
                        </a-tab-pane>
                    </a-tabs>
                </a-modal>
            </div>
        </div>
    </div>
</template>

<script>
import Clipboard from "clipboard"; // 复制文字
import MyPagination from "@/components/pagination/MyPagination";
import {InvitationCodeSetStatusApi, QueryInvitationCode} from "@/request/api/marketingCenter";
import { baseMixin } from "@/store/app-mixin";
import { Modal, message } from "ant-design-vue";
import { codeFn } from "@/utils/tools";

export default {
    mixins: [baseMixin],
    components: {
        MyPagination,
    },
    data() {
        return {
            isShowDisabled: false, // 是否显示立即失效按钮
            uniqueId: "",
            tableList: [],
            tableLoading: false,
            columns: [
                { title: "邀请码", dataIndex: "uniqueId", key: "uniqueId" },
                { title: "领取会员", dataIndex: "toUserNickName", key: "toUserNickName" },
                { title: "领取会员账号", dataIndex: "toUserName", key: "toUserName" },
                { title: "领取状态", dataIndex: "status", key: "status", scopedSlots: { customRender: "status" }},
                {
                    title: "领取时间",
                    dataIndex: "receiveTime",
                    key: "receiveTime",
                    sorter: (a, b) => {
                        let aTime = new Date(a.receiveTime).getTime();
                        let bTime = new Date(b.receiveTime).getTime();
                        return aTime - bTime;
                    },
                },
                {title: "操作", key: "operation", dataIndex: "operation", scopedSlots: { customRender: "operation" }},
            ],
            shareInputVal: null, // 分享弹框的input值
            shareModalVisible: false, // 分享弹框
        };
    },
    created() {
        if (codeFn("/admin/dealer/invitation-record/setStatus")) {this.isShowDisabled = true;}
    },
    methods: {
        // 搜索事件
        handleSearch() {
            this.RecordCodeApi();
        },
        
        RecordCodeApi() {
            this.tableLoading = true;
            this.tableList = []
            let obj = {
                uniqueId: this.uniqueId
            }
            QueryInvitationCode(obj).then((res) => {
                if (res.code == 200) {
                    let newData = []
                    newData.push(res.data)
                    newData.forEach((item) => {
                        item.key = item.uniqueId;
                        item.toUserName = item.toUserName == "" ? "——" : item.toUserName;
                        item.toUserNickName =
                            item.toUserNickName == "" ? "——" : item.toUserNickName;
                        item.receiveTime =
                            item.receiveTime == null ? "——" : item.receiveTime;
                    });
                    this.tableList = newData
                }
                this.tableLoading = false;
            });
        },
        
        // 立即失效
        handleInvalid(record) {
            const that = this;
            Modal.confirm({
                title: "设为失效后，邀请码将不可继续使用!",
                okText: "确定",
                okType: "danger",
                cancelText: "取消",
                onOk() {
                    InvitationCodeSetStatusApi(`uniqueId=${record.uniqueId}&status=2`).then((res) => {
                        if (res.code == 200 && res.data) {
                            that.$message.success("失效成功");
                            that.RecordCodeApi();
                        } else {
                            that.$message.error("失效失败");
                        }
                    });
                },
            });
        },
        
        // 分享的按钮
        shareClick(record) {
            this.shareModalVisible = true;
            this.shareInputVal = record.link;
        },
        
        // 分享弹框复制按钮
        handleCopyFun() {
            let clipboard = new Clipboard("#copy_text");
            clipboard.on("success", (e) => {
                message.success("复制成功");
                this.shareModalVisible = false;
                clipboard.destroy(); // 释放内存
            });
            clipboard.on("error", (e) => {
                message.error("复制失败"); // 不支持复制
                this.shareModalVisible = false;
                clipboard.destroy(); // 释放内存
            });
        },
    },
    
    beforeDestroy() {
        sessionStorage.removeItem("tableData");
    },
};
</script>

<style lang="less" scoped>
.invitecode-info {
    background: #ecf8fe;
    padding: 10px 20px;
    margin-bottom: 20px;
    .title {
        font-size: 14px;
        line-height: 20px;
        color: #000;
        margin-bottom: 4px;
    }
    .label,
    .date {
        color: #9fa5af;
    }
}
.table-operator {
    margin-right: 20px;
}
.share {
    width: 95%;
    text-align: center;
    height: 30px;
    line-height: 30px;
    background-color: #efefef;
}
</style>
